import React from 'react';
import styles from './index.module.css'
import Header from '../../components/header';
import Banner from '../../components/banner';
import Brands from '../../components/brands';
import About from '../../components/aboutUs';
import Footer from '../../components/footer';
import Schedule from '../../components/schedule';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { bulldogProducts, hitProducts, siberiaProducts, whiteFoxProducts } from '../../data/products'

const ProductCard = React.lazy(() => import('../../components/products'));

function Home () {
    const products = [bulldogProducts[0], hitProducts[0], siberiaProducts[0], whiteFoxProducts[0]];

    return (
        <div>
            <Header />
            <Banner />
            <Brands />
            <About 
                title={'ABOUT DDG'}
                p1={"DDG Marketing is the sales company servicing DDG Distribution, the holder of the rights to import and distribute GN Tobacco of Sweden's products to the United Kingdom."}
                p2={' We are a team of professionals dedicated to supporting our customers with the highest quality products and superb customer service. Our market knowledge and marketing support through promotions, point of sale and display equipment will help you grow your sales of nicotine and tobacco pouches.'}
                p3={'Call us today to arrange to meet a dedicated sales representative nationwide.'}
            />
            <Grid spacing={3} className={styles['product-container']} container >
                {products.map((product, index) => {
                    return (
                        <Grid xs={6} md={3} key={product.name} className={styles['products']}>
                            <ProductCard
                                className={styles['products']}
                                product={product}
                                key={index}
                                index={index}
                            />
                        </Grid>
                    )
                })}
                <Grid xs={12} md={12} className={styles['btn-view-all-container']}>
                    <a href='/products'>
                        <Button
                            className={styles['btn']}
                            variant="contained">
                            View All
                        </Button>
                    </a>
                </Grid>
            </Grid>
            <Schedule />
            <Footer />
        </div>
    );
}

export default Home;
