import React from 'react';
import styles from './index.module.css'

const Header = () => {
    return (
      <div className={styles['header']}>
        <a href='/'>
        <img className={styles['logo']} src={`${process.env.PUBLIC_URL}/img/logo-white.png`} alt="DDG's website logo" ></img>
        </a>
      </div>
    )
};

export default Header;

