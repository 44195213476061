import React, { useRef } from 'react';
import styles from './index.module.css';
import Grid from '@mui/material/Unstable_Grid2';

const brands = {
  whiteFoxLogo1: `${process.env.PUBLIC_URL}/img/white-fox-logo.png`,
  whiteFoxLogo2: `${process.env.PUBLIC_URL}/img/white-fox-logo-cor.png`,
  siberiaLogo1: `${process.env.PUBLIC_URL}/img/siberia-logo.png`,
  siberiaLogo2: `${process.env.PUBLIC_URL}/img/siberia-logo-cor.png`,
  bulldogLogo1: `${process.env.PUBLIC_URL}/img/bulldog-logo.png`,
  bulldogLogo2: `${process.env.PUBLIC_URL}/img/bulldog-logo-cor.png`,
  hitLogo1: `${process.env.PUBLIC_URL}/img/hit-logo.png`,
  hitLogo2: `${process.env.PUBLIC_URL}/img/hit-logo-cor.png`
}

const ImageToggleOnMouseOver = ({primaryImg, secondaryImg}) => {
  const imageRef = useRef(null);
    return (
      <img 
        className={styles['img-logos']}
        onMouseOver={() => {
          imageRef.current.src = secondaryImg;
        }}
        onMouseOut={() => {
          imageRef.current.src= primaryImg;
        }}
        src={primaryImg} 
        alt=""
        ref={imageRef}
      />
    )
}

const Brands = () => {
    return (
      <Grid className={styles['brands-container']}>
          <Grid xs={6} md={3} className={styles['brands-logos']}>
            <a href='whitefox' target='_blank'>
              <div className={styles['desk']}>
                <ImageToggleOnMouseOver
                  primaryImg={brands.whiteFoxLogo1}
                  secondaryImg={brands.whiteFoxLogo2}
                />
              </div>
              <div className={styles['mobile']}>
                <ImageToggleOnMouseOver
                  primaryImg={brands.whiteFoxLogo2}
                />
              </div>
            </a>
          </Grid>
          <Grid xs={6} md={3}className={styles['brands-logos']}>
            <a href='siberia' target='_blank'>
              <div className={styles['desk']}>
                <ImageToggleOnMouseOver
                  primaryImg={brands.siberiaLogo1}
                  secondaryImg={brands.siberiaLogo2}
                />
              </div>  
              <div className={styles['mobile']}>
                <ImageToggleOnMouseOver
                  primaryImg={brands.siberiaLogo2}
                 />
              </div>  
            </a>
          </Grid>
          <Grid xs={6} md={3}className={styles['brands-logos']}>
            <a href='bulldog' target='_blank'>
              <div className={styles['desk']}>
                <ImageToggleOnMouseOver
                  primaryImg={brands.bulldogLogo1}
                  secondaryImg={brands.bulldogLogo2}
                />
              </div>  
              <div className={styles['mobile']}>
                <ImageToggleOnMouseOver
                  primaryImg={brands.bulldogLogo2}
                />
              </div>  
            </a>
          </Grid>
          <Grid xs={6} md={3}className={styles['brands-logos']}>
            <a href='hit' target='_blank'>
              <div className={styles['desk']}>
                <ImageToggleOnMouseOver
                  primaryImg={brands.hitLogo1}
                  secondaryImg={brands.hitLogo2}
                />
              </div>  
              <div className={styles['mobile']}>
                <ImageToggleOnMouseOver
                  primaryImg={brands.hitLogo2}
                />
              </div>
            </a>
          </Grid>
      </Grid>
        
    )
};
export default Brands
