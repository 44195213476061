import React, { useState, useEffect } from 'react';
import styles from './index.module.css'
import Header from '../../components/header';
import Grid from '@mui/material/Unstable_Grid2';
import { allProductsFix } from '../../data/products'

const ProductCard = React.lazy(() => import('../../components/products'));

function Products () {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
        setLoading(false);
        }, 500);
    }, []);

    return (
        <div>
            {loading ? (
                <div className={styles['loader-container']}>
                    <div className={styles['spinner']}></div>
                </div>
            ) : (
                    <div>
                        <Header />
                        <Grid spacing={3} className={styles['product-container']} container >
                            {allProductsFix.map((product, index) => {
                                return (
                                    <Grid xs={6} md={3} className={styles['products']} key={index}>
                                        <ProductCard
                                            className={styles['products']}
                                            product={product}
                                            index={index}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>
                )}
        </div>
    );
}

export default Products




